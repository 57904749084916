import { useCompanyIDAndProjectID, sortByName } from "../../helpers/utils";
import useQuery from "../../hooks/useQuery";
import React, { useEffect } from "react";
import { documentTypes, documentTypes_project_documentTypes, documentTypesVariables } from "__generated__/documentTypes";
import { Select } from "antd";
import { createDocumentTypeKey, HasDocTypeFields, HasDocTypeFieldsWithName } from "components/documents/documentUtils";
import GET_DOCUMENT_TYPES from "gql/getDocumentTypes.gql";
import { CustomType } from "__generated__/globalTypes";
import { LabeledValue } from "antd/es/select";

interface DocumentTypeSelectProps {
  selected?: HasDocTypeFields | string;
  filterBy?: Nullable<HasDocTypeFields[]>;
  documentTypeCounts?: Map<string, number>; // a map of document type keys to a number which will be put in parathesis
  extraOption?: LabeledValue & { onChange: VoidFunction }; // optionally include an extra dropdown option at the time with name {string}
  onChange(val?: HasDocTypeFieldsWithName): void;
}

export const DocumentTypeSelect = ({
  onChange,
  selected,
  filterBy,
  extraOption,
  documentTypeCounts,
}: DocumentTypeSelectProps) => {
  const { projectID } = useCompanyIDAndProjectID();
  const { data, loading } = useQuery<documentTypes, documentTypesVariables>(GET_DOCUMENT_TYPES, {
    fetchPolicy: "no-cache",
    skip: !projectID,
    variables: { projectId: projectID! },
  });

  const selectedId = typeof selected === "string" ? selected : createDocumentTypeKey(selected);
  const allDocTypes = (data?.project?.documentTypes || []).sort(sortByName);

  const filteredItems = filterBy && filterBy.map((f) => createDocumentTypeKey(f)).filter((f) => f); //filter undefined or null
  const docTypes = allDocTypes
    .filter((docType) => {
      return filteredItems ? filteredItems.includes(createDocumentTypeKey(docType)) : true;
    })
    .filter((docType) => docType.type !== CustomType.Form);

  useEffect(() => {
    if (!loading && data && filterBy && docTypes && !selected) {
      onChange(docTypes[0]);
    }
  }, [data, loading, docTypes, filterBy, selected]);

  let selectedDocType: HasDocTypeFields | string | undefined;
  if (extraOption && extraOption.value === selected) {
    selectedDocType = extraOption.value; // for extra option
  } else {
    selectedDocType = docTypes.find((docType) => createDocumentTypeKey(docType) === selectedId);
    selectedDocType = createDocumentTypeKey(selectedDocType as HasDocTypeFields);
  }

  const docTypeOptions = docTypes.map((d: documentTypes_project_documentTypes) => {
    const key = createDocumentTypeKey(d);
    return (
      <Select.Option key={key} name={d.name} value={key!}>
        {d.name} {documentTypeCounts && documentTypeCounts.has(key!) ? `(${documentTypeCounts.get(key!)})` : "(0)"}
      </Select.Option>
    );
  });

  return (
    <>
      {!loading && (
        <Select
          defaultActiveFirstOption={true}
          placeholder="Select a document type"
          onChange={(key) => {
            if (extraOption && extraOption.value === key) extraOption.onChange();
            else onChange(docTypes.find((d: HasDocTypeFields) => createDocumentTypeKey(d) === key));
          }}
          style={{ width: "100%" }}
          value={selectedDocType}
        >
          {extraOption ? <Select.Option value={extraOption.value}>{extraOption.label}</Select.Option> : null}
          {docTypeOptions}
        </Select>
      )}
    </>
  );
};
