import { useCompanyRoleRequired } from "components/auth/CompanyRoleRequired";
import { UserRolesAtCompany } from "__generated__/globalTypes";
import { useCompanyIDAndProjectID } from "helpers/utils";
import { useIsSSR } from "hooks/useIsSSR";
import { useRouter } from "next/router";
import { ManualUploadDropzone } from "components/ManualUpload/ManualUploadDropzone";
import { ManualUploadManagerProvider } from "components/ManualUpload/ManualUploadManager";
import React from "react";

interface ManualUploadProps {
  allowedRoutes: string[];
  children?: React.ReactNode;
}

/**
 *  Root component of the entire drag and drop functionality
 */
export const ManualUploadDropAndDrop = (props: ManualUploadProps) => {
  const { projectID } = useCompanyIDAndProjectID();
  const isAdmin = useCompanyRoleRequired(UserRolesAtCompany.Admin);
  const router = useRouter();
  const isSSR = useIsSSR();

  // Only activate drop zone on allowed pages
  let dropzoneEnabled = false;
  props.allowedRoutes.forEach((routePath) => {
    dropzoneEnabled = dropzoneEnabled || router.pathname.endsWith(routePath);
  });
  if (router.query["viewingDocID"]) dropzoneEnabled = false; // NO PDFTRON!

  // If (1) not on a project (2) not an admin (3) is SSR
  // then also disable but keep Upload Manager running
  if (!projectID || !isAdmin || isSSR) {
    dropzoneEnabled = false;
  }

  return (
    <ManualUploadManagerProvider>
      <ManualUploadDropzone enabled={dropzoneEnabled}>{props.children}</ManualUploadDropzone>
    </ManualUploadManagerProvider>
  );
};
